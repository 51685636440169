
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { getBatchItem } from "@/api/batch.api";

export interface Data {
  batchItem: unknown;
  isReadonly: boolean;
}
export default defineComponent({
  name: "WaystarForm",
  components: {},
  props: ["batchItem", "claimId", "isReadonly"],
  setup(props) {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      batchItem: {},
      isReadonly: false,
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      if (props.batchItem) {
        data.batchItem = props.batchItem;
      } else {
        data.batchItem = await getBatchItem({claimId: props.claimId});
      }
      
      data.isReadonly = props.isReadonly;
    });

    return {
      organizationId,
      data,
    };
  },
});
