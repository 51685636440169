import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/batch";

export async function getBatches() {
  const request = {
    keyword: "",
    pageNumber: 0,
    pageSize: 100,
    orderBy: ["id"],
  };
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const items = res.data.data;
      //TODO DEBT
      items.forEach((item: any) => {
        if (item.submitDate) item.submitDate = new Date(item.submitDate);
      });

      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addBatch(createBatchRequest) {
  return await ApiService.post(url, createBatchRequest).catch(
    ({ response }) => {
      processErrors(response);
    }
  );
}

export async function getBatch(getBatchRequest) {
  return await ApiService.post(url + "/getNew", getBatchRequest)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getBatchItem(getBatchItemRequest) {
  return await ApiService.post(url + "/getNewItem", getBatchItemRequest)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function submitBatch(batchId) {
  const request = { BatchId: batchId };
  return await ApiService.post(url + "/submit", request as any)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getExistingBatch(batchId) {
  return await ApiService.get(url + "/" + batchId)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}
